<template>
  <div class="p-4 flex space-x-3 bg-white rounded shadow-md">
      <slot name="logo"></slot>
      <p class="flex flex-col justify-center">
          <span class="text-sm font-medium"><slot name="title" ></slot></span>
          <span class="text-2xl font-bold"><slot name="data"></slot></span>
      </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>