<template>
  <div class="p-6">
      <p class="text-2xl font-bold mb-6">Tableau de bord</p>
      <div v-if="stats" class="flex space-x-8 w-full mb-8">
        <stats-card class="w-1/4">
          <p slot="logo"><svgicon name="response" width="48" height="49"></svgicon></p>
          <span slot="title">Total des réponses</span>
          <span slot="data">{{stats.total}}</span>
        </stats-card>
        <stats-card class="w-1/4">
          <p slot="logo"><svgicon name="allParticular" width="48" height="49"></svgicon></p>
          <span slot="title">Nombre de particuliers</span>
          <span slot="data">{{stats.physical}}</span>
        </stats-card>
        <stats-card class="w-1/4">
          <p slot="logo"><svgicon name="allEnterprise" width="48" height="49"></svgicon></p>
          <span slot="title">Nombre d’entrepises</span>
          <span slot="data">{{stats.companies}}</span>
        </stats-card>
        <stats-card class="w-1/4">
          <p slot="logo"><svgicon name="achievement" width="48" height="49"></svgicon></p>
          <span slot="title">Taux d’achèvement</span>
          <span slot="data">{{stats.taux}}%</span>
        </stats-card>
      </div>
      <div class="bg-white rounded-lg p-4 text-sm shadow-md">
        <div class="flex text-sm text-inkNormal-active border-b-2 border-neutralLight mb-6">
            <div @click="view ='particular'" :class="['px-4 py-1 -mb-0.5 cursor-pointer font-bold', view == 'particular'? 'border-b-4 border-blueLink text-blueLink': '']">Particuliers</div>
            <div @click="view ='enterprise'" :class="['px-4 py-1 -mb-0.5 cursor-pointer font-bold', view == 'enterprise'? 'border-b-4 border-blueLink text-blueLink': '']"><span>Entreprises</span></div>
        </div>
        <div>
          <div v-if="view =='particular'">
            <particular></particular>
          </div>
          <div v-else>
            <enterprise></enterprise>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Enterprise from '../components/Enterprise.vue'
import Particular from '../components/Particular.vue'
import StatsCard from '../components/StatsCard.vue'
export default {
  components: { StatsCard, Particular, Enterprise },
  data(){
    return {
      view: 'particular'
      
    }
  },

  computed: {
    stats (){
      return this.$store.getters.getStatsData
    }
  },

  methods: {
   
  },
}
</script>

<style>

</style>