<template>
  <div class="relative">
    <div class="p-2 border rounded cursor-pointer" @click="$emit('toggle')">
      <div class="period"> {{ selectedPeriod }} </div>
    </div>
    <div class="absolute bg-white mt-3 w-72 shadow p-3" v-if="show">
      <div class="flex w-full">
        <!-- How v-calendar -->
        <v-date-picker class="inline-block h-full mr-2" :model-config="modelConfig" v-model="date.from">
          <template v-slot="{inputValue, togglePopover }">
            <label for="">De</label>
            <div class="flex items-center" @click="togglePopover()">
              <button
                class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </button>
              <input
                :value="inputValue"
                class="bg-white text-gray-700 w-full py-1.5 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                readonly
              />
            </div>
          </template>
        </v-date-picker>
        <v-date-picker class="inline-block h-full mr-2" :model-config="modelConfig" v-model="date.to">
          <template v-slot="{inputValue, togglePopover }">
             <label for="">À</label>
            <div class="flex items-center" @click="togglePopover()">
              <button
                class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </button>
              <input
                :value="inputValue"
                class="bg-white text-gray-700 w-full py-1.5 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                readonly
              />
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="my-3">
        <base-button color="primary" :loading="applyLoading" full @click.prevent="onApplyFilter" text="Appliquer le filtre" />
      </div>
      <div class="my-3">
        <base-button color="gray" full @click.prevent="onResetFilter" text="Annuler le filtre" />
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker as VDatePicker } from "v-calendar";
export default {
  components: {
    VDatePicker
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },

    applyLoading: {
      type: Boolean,
      default: false
    },

    filter: {
      type: Function,
      default() {

      }
    }
  },

  data () {
    return {
      selectedPeriod: 'Séléctionner une période',
      date: {
        from: '',
        to: ''
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
    }
  },

  methods: {
    onApplyFilter () {
      this.$emit('filter:apply', this.date)
    },

    onResetFilter () {
      this.$emit('filter:reset')
    }
  }
}
</script>

<style>

</style>